const all = {
  all: "ทั้งหมด",
  point_channel: "ช่องทางสะสมคะแนน",
  member_tier: "ระดับสมาชิก",
  total_spend: "ยอดสะสม",
  branch_spending: "ยอดสะสมสาขาของคุณ",
  current_total_spend: "ยอดสะสมปัจจุบัน",
  more_spending: "สะสมยอดเพิ่ม",
  marketplace_channel: "ช่องทางการเชื่อมต่อ",
  placeholder_marketplace_select: "กรุณาเลือกช่องทางการสั่งซื้อ",
  placeholder_marketplace_select_2: "กรุณาเลือกร้านค้า",
  marketplace_channel: "ช่องทางการเชื่อมต่อ",
  status: "สถานะ",
  connect_status: "เชื่อต่อสมบูรณ์",
  connect_status_error: "",
  btn_connect_market: "เพิ่มช่องทางเชื่อมต่อ",
  btn_connect_success_market: "เชื่อมต่อสมบูรณ์",
  btn_disconnect_market: "ลบการเชื่อมต่อ",
  btn_connect: "เชื่อมต่อ",
  redeem_quantity: "จำนวนสินค้าที่แลก",
  point_used: "จำนวนคะแนนที่ใช้",
  expired: "หมดอายุ",
  expired_date: "วันหมดอายุ",
  member_expired_date: "วันหมดอายุสมาชิก",
  point_total: "คะแนนสะสมของคุณ",
  currency_unit: "บาท",
  note: "หมายเหตุ",
  note_market: "สำหรับการเชื่อมต่อช่องทางต่างๆ",
  note_market_1:
    "การเชื่อมต่อช่องทางครั้งแรก สามารถเชื่อมต่อได้หลังจากการกดยืนยันรับสินค้า 1 ชั่วโมง",
  note_market_2:
    "สำหรับครั้งถัดไป ข้อมูลคำสั่งซื้อจะเข้าอัตโนมัติ หลังจากยืนยันรับสินค้า ภายใน 1 ชั่วโมง",
  history: "ประวัติการใช้",
  see_detail: "ดูรายละเอียด",
  description: "รายละเอียด",
  redeem_date: "แลกได้ถึง",
  use: "ใช้",
  profile_general: "ข้อมูลทั่วไป",
  profile_address: "ข้อมูลที่อยู่",
  profile_additional: "ข้อมูลเพิ่มเติม",
  policy: "นโยบายคุ้มครองข้อมูลส่วนบุคคล",
  pdpa: "จัดการข้อมูลส่วนตัว",
  term: "ข้อกำหนดเงื่อนไข",
  billing: "ที่อยู่ใบกำกับภาษี",
  shipping: "ที่อยู่จัดส่ง",
  contact: "บุคคลติดต่อ",
  add_billing: "เพิ่มที่อยู่ใบกำกับภาษี",
  add_shipping: "เพิ่มที่อยู่จัดส่ง",
  add_contact: "เพิ่มบุคคลติดต่อ",
  info_billing: "ข้อมูลที่อยู่ใบกำกับภาษี",
  info_shipping: "ข้อมูลที่อยู่จัดส่ง",
  info_contact: "ข้อมูลบุคคลติดต่อ",
  use_as_profile: "ใช้ที่อยู่ส่วนตัว",
  add: "เพิ่ม",
  ext: "ต่อ",
  default: "ค่าเริ่มต้น",
  save: "บันทึก",
  confirm: "ยืนยัน",
  branch_select_coupon: "เลือกสาขาที่ใช้คูปอง",
  confirm_used: "ยืนยันการใช้งาน",
  already_used: "ใช้งานเรียบร้อย",
  cancel: "ยกเลิก",
  close_window: "ปิดหน้าต่างนี้",
  mission_detail: "รายละเอียดมิชชั่น",
  mission_reward: "ชุดของขวัญ",
  get_reward: "รับรางวัล",
  spending: "สะสมยอดซื้อสินค้า",
  stamp_collect: "สะสมแสตมป์",
  stamp: "ดวง",
  finish: "เสร็จสิ้น",
  process: "กำลังดำเนินการ",
  privilege_receive_success: "ยืนยันรับสิทธิสำเร็จ",
  privilege_code: "โค้ดสิทธิพิเศษ",
  privilege_code_error: "โค้ดสิทธิพิเศษไม่ถูกต้อง",
  privilege: "สิทธิพิเศษ",
  placeholder: "กรุณากรอก",
  use_coupon: "กดเพื่อใช้คูปอง",
  coupon_code: "รหัสคูปอง",
  coupon_note:
    "คูปองนี้ถ้ากดยืนยันแล้วจะถูกใช้งานทันที\nโปรดแสดงให้พนักงานทราบ",
  point_receive_total: "จำนวนคะแนนที่ได้รับ/ใช้งาน",
  list: "รายการ",
  profile: "ข้อมูลส่วนตัว",
  privilege: "สิทธิประโยชน์",
  logout: "ออกจากระบบ",
  member_tier_expired: "ระดับสมาชิกจะหมดอายุภายใน",
  to_upgrade: "เพื่ออัปเกรด",
  maintain: "เพื่อรักษาระดับคะแนน",
  next_tier: "ลำดับต่อไป",
  keep_current: "เพื่อรับสถานะ",
  qr_count_time: "QR Code รหัสนี้จะหมดอายุภายใน",
  total_spend_unit: "ยอดสะสม (บาท)",
  count_purchase_times: "ซื้อสินค้า (ครั้ง)",
  count_purchase_unit: "ซื้อสินค้า (บาท)",
  collect_point: "คะแนนสะสม",
  collect_point_all: "คะแนนสะสมทั้งหมด",
  total_collect_point: "ยอดสะสมคะแนน",
  back: "กลับไปหน้าหลัก",
  need_point: "ต้องการคะแนนเพิ่ม",
  complate_profile: "ความครบถ้วนของข้อมูล",
  edit_profile: "แก้ไขข้อมูลส่วนตัว",
  edit: "แก้ไข",
  firstname: "ชื่อ",
  lastname: "นามสกุล",
  email: "อีเมล",
  telephone: "เบอร์โทรศัพท์",
  birthday: "วันเกิด",
  point_expired: "คะแนนที่จะหมดอายุ",
  success: "สำเร็จ",
  confirm_header_text: "คุณต้องการทำรายการนี้ หรือไม่ ?",
  amount: "จำนวน",
  default_input_validate: "กรุณากรอกข้อมูลให้ครบถ้วน",
  redeem_text: "ของขวัญ",
  search: "ค้นหา",
  clear_filter: "ล้างตัวกรอง",
  remain: "คงเหลือ",
  piece: "ชิ้น",
  no_detail: "ไม่มีรายละเอียด",
  no_data: "ไม่พบข้อมูล",
  number_times: "จำนวนครั้ง",
  loading: "กรุณารอสักครู่",
  create_date: "วันที่สร้า",
  way_to_earn: "เพิ่มช่องทางการสะสมคะแนน",
  free_point: "รับคะแนนสะสมฟรี",
  purchase_channel: "ช่องทางการสั่งซื้อ",
  market_name: "ชื่อร้านค้า",
  order_no: "หมายเลขสั่งซื้อ (Order No.)",
  track_id: "หมายเลขจัดส่ง (Tracking ID.)",
  info_manage: "จัดการข้อมูลส่วนตัว",
  additional_note: "ระบุเหตุผลเพิ่มเติม",
  request_information: "ขอเข้าถึงข้อมูล",
  request_transfer_information: "ขอให้โอนข้อมูล",
  request_deny_information: "ขอให้เพิกถอนความยินยอม",
  request_edit_information: "ขอแก้ไขข้อมูล",
  request_suspend_information: "ขอให้ระงับและคัดค้านการใช้ข้อมูล",
  request_delete_information: "ขอให้ลบข้อมูล",
  login_title: "เข้าสู่ระบบ",
  register_title: "เข้าสู่ระบบ",
  telephone_title: "ลงทะเบียน",
  telephone_subtitle:
    "หากคุณเคยลงทะเบียนผ่านสาขาของ D-dot Thailand อยู่แล้ว สามารถระบุเบอร์โทรศัพท์เพื่อเชื่อมข้อมูล",
  redeem_exceed_quota_per_customer_title: "คุณได้แลกสิทธิครบตามที่กำหนดแล้ว",
  redeem_exceed_quota_title: "ของรางวัลหมด",
  redeem_button: "แลกสินค้า",
  redeem_remark:
    "ท่านสามารถรับชุดของขวัญได้ที่เคาน์เตอร์อาวร์กลาสสาขาที่ท่านเลือกภายใน 30-45 วันทำการ",
  mission_title: "Membership Activity",
  upcomming_text: "Upcoming Reward",
  upload_receipt_loading:
    "ระบบกำลังทำการตรวจสอบ ขอขอบคุณลูกค้าที่ใช้บริการ ท่านสามารถกลับมาตรวจสอบสถานะได้ภายหลัง",
  header_shipping_address_form: "",
  footer_shipping_address_form: "",
  header_billing_address_form: "",
  footer_billing_address_form: "",
  header_contact_address_form: "",
  footer_contact_address_form: "",
  header_register_form: "",
  footer_register_form: "",
  delete: "ลบ",
  shipping_methods: "รูปแบบการจัดส่ง",
  social_media: "โซเซียลมีเดีย",
  your_telephone: "เบอร์โทรศัพท์ของคุณ",
  main_telephone: "เบอร์โทรศัพท์ (เบอร์หลัก)",
  optional_telephone: "เบอร์โทรศัพท์สำรอง",
  your_spending: "ยอดใช้จ่ายของคุณ",
  close: "ปิด",
  default_select_validate: "กรุณาเลือกข้อมูล",
  filter: "กรองข้อมูล",
  choose: "กำลังเลือก",
  confirm_logout: "คุณต้องการออกจากระบบหรือไม่?",
  next_page: "หน้าถัดไป",
  send_otp_message: "เราได้ส่งรหัสผ่านไปยัง เบอร์โทรศัพท์",
  fill_otp: "กรุณาใส่รหัส OTP 6 หลักในช่องด้านล่าง",
  change_telephone: "เปลี่ยนเบอร์โทรศัพท์",
  skip: "ข้าม",
  earn_channel: "ช่องทางการสะสมคะแนน",
  privilege_for: "สิทธิประโยชน์สำหรับ",
  setting: "ตั้งค่า",
  change_language: "เปลี่ยนภาษา",
  language: "ภาษา",
  date: "เวลาปัจจุบัน",
  wait_otp: "กรุณารอสักครู่ก่อนส่งใหม่อีกครั้ง",
  send_otp: "ขอรหัส OTP ใหม่อีกครั้ง",
  register: "สมัครสมาชิก",
  consent_policy_message:
    "ฉันขอให้ความยินยอมโดยชัดแจ้งในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของฉัน ดังมีรายละเอียดปรากฏตาม",
  consent_term_message: "ฉันต้องการรับสิทธิพิเศษและโปรโมชั่น รวมถึงข่าวสารจาก",
  your_receipt: "ใบเสร็จของคุณ",
  history_use: "ประวัติ",
  per_day: "ต่อวัน",
  limit_user_receipt: "วันนี้คุณได้ส่งตรวจสอบครบแล้ว",
  no_receipt_upload: "ยังไม่มีใบเสร็จ กรุณาส่งใบเสร็จ",
  send_receipt: "ส่งใบเสร็จ",
  confirm_send_receipt: "ยืนยันการส่งใบเสร็จ ?",
  confirm_message_send_receipt:
    "ยืนยันการส่งใบเสร็จ กรุณาตรวจสอบความถูกต้องก่อนส่งใบเสร็จ",
  receipt_campaign_date: "ขออภัย Campaign นี้สามารถใช้งานได้วันที่",
  receipt_campaign_expired: "ขออภัย Campaign นี้หมดอายุแล้ว",
  receipt_campaign_not_found: "ขออภัยไม่พบ Campaign ที่ร่วมรายการ",
  go_to_history: "ไปที่หน้าประวัติ",
  receipt_upload_success: "อัพโหลดใบเสร็จสำเร็จ",
  receipt_unit: "ใบ",
  confirm_delete_receipt: "ยืนยันการลบใบเสร็จ ?",
  confirm_message_delete_receipt:
    "ยืนยันการลบใบเสร็จ กรุณาตรวจสอบความถูกต้องก่อนลบใบเสร็จ",
  validate_input_min_length: "กรุณากรอกอย่างน้อย",
  validate_input_max_length: "กรุณากรอกไม่เกิน",
  validate_input_number: "กรุณากรอกตัวเลขเท่านั้น",
  validate_english_only: "กรอกเฉพาะตัวอักษรเท่านั้น",
  validate_english_num_only: "กรอกเฉพาะตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น",
  validate_id_card: "กรุณากรอกรูปแบบบัตรประชาชนให้ถูกต้อง",
  validate_email: "กรุณากรอกอีเมล (เช่น email@example.com)",
  character: "ตัว",
  news: "ข่าวสาร",
  spending_history: "ประวัติยอดสะสม",
  all_accumulate: "ยอดสะสมทั้งหมด",
  send_check: "ส่งตรวจสอบ",
  invoice_no: "เลขที่ใบเสร็จ",
  receipt_cancel_invoice: "ยกเลิกรายการนี้",
  receipt_max_error_message_1:
    "เนื่องจากมีการส่งใบเสร็จที่ไม่สามารถตรวจสอบได้เกิน",
  receipt_max_error_message_2:
    "ครั้งต่อวัน\n กรุณาลองใหม่ในวันพรุ่งนี้ หรือ ติดต่อเจ้าหน้าที่",
  receipt_max_upload_message: "วันนี้คุณได้ส่งตรวจสอบครบแล้ว",
  error_message: "เกิดข้อผิดพลาด",
  upload_image_text: "เพิ่มรูปภาพ",
  upgrade_unit: "บาท",
  allow_login_error_message: "ไม่ให้จ้า",
  email_login_message: "เข้าสู่ระบบด้วยอีเมล",
  telephone_login_message: "เข้าสู่ระบบด้วยเบอร์โทรศัพท์",
  send_email_otp_message: "เราได้ส่งรหัสผ่านไปยัง Email",
  change_email: "",
  change_email_title: "",
  email_change_subtitle: "",
  email_subtitle: "",
  email_title: "",
  fill_otp_email_login: "",
  wait_otp_email_login: "",
  send_otp_email_login: "",
  consent_policy_message_email_login_pdpa_email: "",
  consent_term_message_email_login_policy_email: "",
  policy_email: "",
  term_email: "",

  win_reward: "ยินดีด้วยคุณได้รับรางวัล",
  received_prize: "ได้รับของรางวัล",
  received_prize_description:
    "ดวงของคุณยังดีอยู่ กดดูของรางวัลเพื่อไปต่อได้เลย",
  not_received_prize: "คุณไม่ได้รับรางวัล",
  view_random_reward_prize: "ดูของรางวัล",
  view_mission_prize: "ดูของรางวัล",
  open_box: "เปิดกล่องรางวัล",
  open_all_box: "เปิดทั้งหมด",
  send_more_code: "ส่งรหัสเพิ่ม",
  random_reward_title: "สแกนลุ้นรับกับ",
  mission_random_reward_title: "สแกนลุ้นรับกับ",
  random_reward_description:
    'ถ้าเตรียมดวงพร้อมแล้ว "เปิดกล่องรางวัล" เพื่อลุ้นโชคกันเลย',
  mission_random_reward_description:
    'ถ้าเตรียมดวงพร้อมแล้ว "เปิดกล่องรางวัล" เพื่อลุ้นโชคกันเลย',
  not_receive_reward: "ไม่ได้รับของรางวัล",
  receive_reward: "ได้รับ"

  // your_point: "คะแนนของคุณ",
  // point: "คะแนน",
  // current_point: "คะแนนสะสมปัจจุบัน",
  // point_remain: "คะแนนคงเหลือ",
  // center_point: "คะแนนพิเศษ (Center Point)",
  // point_history: "ประวัติคะแนนสะสม",  // branch_point: "คะแนนสาขาของคุณ",
};

const input = {};
const th = { ...input, ...all };
export default th;
